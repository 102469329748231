<template>
  <v-dialog v-model="visible" width="600">
    <v-card>
      <v-card-text>
        <base-material-card color="indigo darken-4">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">{{nombre + ' ' + apellidos}}</div>
            <div>{{idCuenta}}</div>
          </template>
          <v-text-field v-model="correo" label="Correo" disabled />
          <v-text-field v-model="nombreCarrera" label="Carrera" disabled />
          <v-text-field v-model="tutor" label="Tutor" disabled />
          <v-text-field v-model="tipo" label="Tipo de Usuario" disabled />

          <v-btn color="verdeBoton" @click="cancelar()">Ocultar</v-btn>
        </base-material-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalCuentaHistorial",
  data() {
    return {
      visible: false,
    };
  },
  props: {
    dialogMaterial: {
      type: Boolean,
      default: false,
    },
    nombre: {
      type: String,
      default: "",
    },
    apellidos: {
      type: String,
      default: "",
    },
    idCuenta: {
      type: String,
      default: "",
    },
    correo: {
      type: String,
      default: "",
    },
    nombreCarrera: {
      type: String,
      default: "",
    },
    tutor: {
      type: String,
      default: "",
    },
    tipo: {
      type: String,
      default: "",
    },
    cuenta: Object,
    carrera: Object,
    tutor: String,
  },
  watch: {
    dialogMaterial() {
      if (this.dialogMaterial && !this.visible) this.show();
      else if (!this.dialogMaterial && this.visible) this.hide();
    },
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
    cancelar() {
      this.hide();
      this.$emit("ocultar");
    },
  },
};
</script>
