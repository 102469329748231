<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Filtros para la Tabla</div>
          </template>
          <!-- Filtrado por Descripcion  -->
          <v-text-field v-model="matriculaFiltroValor" label="Matricula" append-icon="mdi-magnify" />
          <!-- Filtrado por Material -->
          <v-combobox
            v-model="materialNuevo"
            :items="this.materiales"
            item-text="nombre"
            item-value="idMaterial"
            label="Material"
            return-object
            auto-select-first
          />
          <!-- Filtrado por Descripcion  -->
          <v-text-field
            v-model="descripcionFiltroValor"
            label="Descripcion"
            append-icon="mdi-magnify"
          />
          <!-- Filtrado por Estado  -->
          <v-select v-model="row" :items="estado" hide-details label="Estado"></v-select>
          <!-- Diseño de las fechas -->
          <v-row>
            <v-col cols="6" md="6">
              <v-menu
                ref="menuInicial"
                v-model="menuInicial"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs}">
                  <v-text-field
                    v-model="dateInicioFormateada"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <!-- Picker de las fechas de inicio -->
                <v-date-picker
                  v-model="dateInicio"
                  no-title
                  @input="menuInicial = false"
                  class="pa-0 ma-0"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Diseño de las fechas -->
            <v-col cols="6" md="6">
              <v-menu
                ref="menuFinal"
                v-model="menuFinal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs}">
                  <v-text-field
                    v-model="dateFinalFormateada"
                    label="Fecha Final"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFinal"
                  no-title
                  class="pa-0 ma-0"
                  @input="menuFinal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn @click="exportPDF">Generar PDF Cuentas.PDF</v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="8" lg="8">
        <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)">
          <v-data-table
            :headers="headers"
            :items="prestamos"
            class="elevation-2 category-table"
            :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
                  }"
            dense
            mobile-breakpoint
            no-data-text="No se Encuentran Préstamos"
            @click:row="handlerClick"
          />
        </v-responsive>
      </v-col>
    </v-row>

    <ModalInformacion
      :dialogMaterial="dialogMaterial"
      :nombre="nombre"
      :apellidos="apellidos"
      :idCuenta="idCuenta"
      :correo="correo"
      :nombreCarrera="nombreCarrera"
      :tutor="tutor"
      :tipo="tipo"
      @ocultar="dialogMaterial = !dialogMaterial"
    />
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import ModalInformacion from "../component/ModalCuentaHistorial";

export default {
  name: "Historial",
  components: {
    ModalInformacion,
  },
  data() {
    return {
      row: "PRESTADO",
      dateInicio: "",
      dateFinal: "",
      dateInicioFormateada: "",
      dateFinalFormateada: "",
      dialogMaterial: false,
      // Headers para las tablas.
      headers: [
        {
          text: "Matricula",
          align: "left",
          sortable: false,
          value: "idCuenta",
          width: "10%",
          filter: this.matriculaFiltro,
        },
        {
          text: "Material",
          value: "material",
          aling: "left",
          with: "5%",
          filter: this.materialFiltro,
        },
        { text: "Desc.", value: "descripcion", filter: this.descripcionFiltro },
        { text: "Prefijo", value: "potenciador" },
        {
          text: "Estado", value: "estado", filter: this.tipoPrestamosFiltro
        },
        {
          text: "Fecha Prestado",
          value: "fechaPedido",
          filter: this.fechaFiltro,
        },
        {
          text: "Fecha Devuelto",
          value: "fechaDevuelto",
          filter: this.fechaFiltro,
        },
      ],
      cantidadPrestados: 0,
      cantidadDevueltos: 0,
      menuInicial: false,
      menuFinal: false,
      //Variables para el Modal
      nombre: "",
      apellidos: "",
      idCuenta: "",
      correo: "",
      nombreCarrera: "",
      tipo: "",
      tutor: "",
      //Variables para el filtro
      materialNuevo: null,
      materialFiltroValor: "",
      estadoFiltroValor: "",
      matriculaFiltroValor: "",
      descripcionFiltroValor: "",
      tutorFiltroValor: "",
      filtrarPrestamos: "",
      estado: ["PRESTADO", "DEVUELTO", "SOLICITADO", "TODOS"],
      // Informacion para las tablas.
      cuentas: [], //Aqui se guardaran toda las cuentas con prestamos PRESTADOS O DEVUELTOS
      prestamos: [],
      materiales: [],
    };
  },
  mounted() {
    /**
     * Aqui hacemos la peticion de los materiales para ser utilizados
     * Se almacenan todos los materiales en un array.
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        materiales.unshift("NINGUNO");
        this.materiales = materiales;
      })
      .catch((err) => {
        return err;
      });

    /**
     * En este fecth obtenemos todas los prestamos que tienen prestamos PRESTADOS o DEVUELTOS
     * Se guarda en el array prestamos
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/historial", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((prestamos) => {
        this.prestamos = prestamos;
      });
  },
  watch: {
    dateInicio() {
      this.dateInicioFormateada = this.formatDate(this.dateInicio);
    },
    dateFinal() {
      this.dateFinalFormateada = this.formatDate(this.dateFinal);
    },
  },
  methods: {
    exportPDF() {
      fetch(
        process.env.VUE_APP_URL_BACKEND +
          "/administrador/cuentasConPrestamos",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
        }
      )
        .then((response) => {
          return response.json(); 
        })
        .then((cuentas) => {
          ///console.log(cuentas);
          const doc = new jsPDF();
          let cuentasAMostrar = [];
          cuentas.forEach((cuenta) => {
            let datosCuenta = [
              cuenta.idCuenta,
              cuenta.nombre + " " + cuenta.apellidos,
              cuenta.correo,
              cuenta.carrera,
            ];
            cuentasAMostrar.push(datosCuenta);
          });
          doc.autoTable({
            head: [["Mátricula", "Nombre Completo", "Correo", "Carrera"]],
            body: cuentasAMostrar,
          });

          doc.save("CuentasConPrestamos.pdf");
        });
    },
    //Metodo para dar el formato a la fecha
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    handlerClick(value) {
      this.nombre = value.cuenta.nombre;
      this.apellidos = value.cuenta.apellidos;
      this.idCuenta = value.idCuenta.toString();
      this.correo = value.cuenta.correo;
      this.nombreCarrera = value.carrera;
      this.tipo = value.cuenta.tipo;
      this.tutor = value.tutor;
      this.dialogMaterial = !this.dialogMaterial;
    },
    /**
     * Este metodo escucha cuando se le hace un click a tabla izquierda y lo que hace es
     * obtener los prestamos de la cuenta a la que acaba de hacer click y mostrarlos en la tabla derecha
     */
    escucharClickTabla(cuentaTabla) {
      this.prestamos = []; //Limpiamos el array de prestamos para poner nuevos
      //Hacemos un for por cada URL de prestamo que tiene la cuenta y realizamos un fetch al backend
      cuentaTabla.prestamos.forEach((prestamo) => {
        fetch(prestamo, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((prestamoAux) => {
            let newDate = this.$cambiarFormatoDate(prestamoAux.fechaPedido);
            prestamoAux.fechaPedido = newDate;
            this.prestamos.push(prestamoAux);
          });
      });
    },
    /**
     * Filtro para el el tipo de material, si esta prestado o devuelto o ambos.
     * @param value es el valor que sera testeado
     * @returns {boolean}
     */
    tipoPrestamosFiltro(value) {
      let tipoPrestamo = this.row;
      if( tipoPrestamo == "Todos")
        return true;
      const r = value.toLowerCase() == tipoPrestamo.toLowerCase();
      return r;
    },
    /**
     * Filtro para el material
     * @param value es el valor que sera testeado
     * @returns {boolean}
     */
    materialFiltro(value) {
      // Primero checamos que tenga algo para filtrar y si no lo sacamos de la funcion
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "NINGUNO" ||
        this.materialNuevo == ""
      ) {
        this.materialFiltroValor = "";
      } else {
        this.materialFiltroValor = this.materialNuevo.nombre;
      }
      //Checamos el loop para verificar el valor que estamos buscando
      return value
        .toLowerCase()
        .includes(this.materialFiltroValor.toLowerCase());
    },
    matriculaFiltro(value) {
      if (!this.matriculaFiltroValor) {
        return true;
      }
      return value
        .toString()
        .toLowerCase()
        .includes(this.matriculaFiltroValor.toString().toLowerCase());
    },
    descripcionFiltro(value) {
      if (!this.descripcionFiltroValor) {
        return true;
      }
      return value
        .toString()
        .toLowerCase()
        .includes(this.descripcionFiltroValor.toString().toLowerCase());
    },
    tutorFiltro(value) {
      // Primero checamos que tenga algo para filtrar y si no lo sacamos de la funcion
      if (!this.tutorFiltroValor) {
        return true;
      }
      //Checamos el loop para verificar el valor que estamos buscando
      return value.toLowerCase().includes(this.tutorFiltroValor.toLowerCase());
    },
    fechaFiltro(value) {
      if (this.dateInicioFormateada != "" && this.dateFinalFormateada != "") {
        if (
          Date.parse(value) >= Date.parse(this.dateInicioFormateada) &&
          Date.parse(value) <= Date.parse(this.dateFinalFormateada)
        ) {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
